import React from "react";
import { graphql } from "gatsby";
import PageTemplate from "@pageTemplates/HomePage";
import resolveImage from "@dataResolvers/resolveImage";
// eslint-disable-next-line no-unused-vars
import ImageFragment from "@fragments/ImageFragment";
import QueryContainer, { HeadTemplate } from "../QueryContainer";

export const query = graphql`
  query HomeQuery($siteId: [Craft_QueryArgument]) {
    craft {
      entry(section: "home", siteId: $siteId) {
        ... on Craft_home_home_Entry {
          title
          metaTitle
          metaDescription
          metaImage {
            ...ImageFragment
          }
          # hero
          heroLabel: heading0
          heroHeading: heading1
          heroSVG: code0
          heroTranscriptText: heading8
          heroTranscriptSubtext: descriptor1
          # herAudioTitle:
          heroAudio: audio0 {
            url
            title
          }
          heroTranscript: transcript0 {
            ... on Craft_transcripts_default_Entry {
              uid
              heading: title
              subheading: heading0
              copy: copy0
            }
          }
          # opening
          openingHeading: heading2
          openingSubHeading: descriptor0
          openingCopy: copy0
          openingImage: image0 {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
            ... on Craft_awsS3_Asset {
              attribution: heading0
            }
          }
          # challenge
          challengeHeading: heading3
          challengeCopy: copy1
          # approach
          approachHeading: heading4
          approachCopy: copy2
          approaches {
            ... on Craft_approaches_default_Entry {
              uid
              title: title
              copy: copy0
              shortTitle: heading0
              image: image0 {
                width
                height
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url
                ... on Craft_awsS3_Asset {
                  attribution: heading0
                }
              }
            }
          }
          # results
          resultsHeading: heading5
          resultsCopy: copy3
          mapHeading: heading6
          stats {
            ... on Craft_stats_labelStatLabel_BlockType {
              type: typeHandle
              uid
              prefixLabel
              stat
              suffixLabel
            }
            ... on Craft_stats_statLabel_BlockType {
              type: typeHandle
              uid
              stat
              label
            }
            ... on Craft_stats_shortStats_BlockType {
              type: typeHandle
              uid
              stats {
                stat
                label
              }
            }
          }
          # stories
          storiesIntroImage: image1 {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
            ... on Craft_awsS3_Asset {
              attribution: heading0
            }
          }
          storiesHeading: heading7
          storiesLink: link2 {
            text
            url
          }
          storiesImages {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
            ... on Craft_awsS3_Asset {
              attribution: heading0
            }
          }
          # pre-footer
          preFooterNav {
            ... on Craft_preFooterNav_row_BlockType {
              heading
              descriptor
              button {
                url
                text
              }
              links {
                ... on Craft_links_BlockType {
                  listlink {
                    text
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const dataResolver = ({ entry }) => {
  const {
    title,
    // hero
    heroLabel,
    heroHeading,
    heroSVG,
    heroTranscriptText,
    heroTranscriptSubtext,
    heroAudio,
    heroTranscript,
    // opening
    openingHeading,
    openingSubHeading,
    openingCopy,
    openingImage,
    // challenge
    challengeHeading,
    challengeCopy,
    // approach
    approachHeading,
    approachCopy,
    approaches,
    // results
    resultsHeading,
    resultsCopy,
    mapHeading,
    stats,
    // stories
    storiesIntroImage,
    storiesHeading,
    storiesLink,
    storiesImages,
    // prefooter
    preFooterNav,
  } = entry;
  return {
    meta: {
      title,
    },
    hero: {
      label: heroLabel,
      heading: heroHeading,
      svg: heroSVG,
      transcriptText: heroTranscriptText,
      transcriptSubtext: heroTranscriptSubtext,
      audio: heroAudio?.[0]?.url,
      audioTitle: heroAudio?.[0].title,
      transcript: heroTranscript?.[0],
    },
    opening: {
      heading: openingHeading,
      subHeading: openingSubHeading,
      copy: openingCopy,
      image: resolveImage(openingImage),
    },
    challenge: {
      heading: challengeHeading,
      copy: challengeCopy,
    },
    approach: {
      heading: approachHeading,
      copy: approachCopy,
      approaches: approaches.map((a, i) => ({
        ...a,
        heading: a.title,
        backgroundImage: resolveImage(a.image),
        previousText: approaches?.[i - 1]?.shortTitle,
        nextText: approaches?.[i + 1]?.shortTitle,
      })),
    },
    results: {
      heading: resultsHeading,
      copy: resultsCopy,
      mapHeading,
      stats,
    },
    stories: {
      introImage: resolveImage(storiesIntroImage),
      heading: storiesHeading,
      link: storiesLink,
      images: storiesImages.map(img => resolveImage([img])),
    },
    preFooter: preFooterNav?.map(row => ({
      header: row?.heading,
      copy: row?.descriptor,
      button: row?.button,
      list: row?.links?.map(l => l?.listlink),
    })),
  };
};

export const Head = ({ data, ...rest }) => {
  const { craft } = data;
  const resolvedData = dataResolver(craft);
  return <HeadTemplate {...rest} data={resolvedData} />;
};

const Page = props => (
  <QueryContainer
    template={PageTemplate}
    dataResolver={dataResolver}
    queryName="HomeQuery"
    {...props}
  />
);

export default Page;
