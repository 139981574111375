import React, { useRef } from "react";
import loadable from "@loadable/component";

const DefaultPageContainer = loadable(() =>
  import("@templates/DefaultPageContainer")
);

const Footer = loadable(() => import("@organisms/Footer"));
const HeroHome = loadable(() => import("@organisms/HeroHome"));
const Accordion = loadable(() => import("@organisms/Accordion"));
const StoriesOfChange = loadable(() => import("@organisms/StoriesOfChange"));
const Opening = loadable(() => import("@organisms/Opening"));
const Challenge = loadable(() => import("@organisms/Challenge"));
const ArchBackground = loadable(() => import("@organisms/ArchBackground"));
const Approach = loadable(() => import("@templates/Approach"));
const Results = loadable(() => import("@templates/Results"));

const HomePage = ({ data }) => {
  const { hero, opening, challenge, approach, results, stories, preFooter } =
    data;

  const openingRef = useRef();
  const challengeRef = useRef();
  const approachRef = useRef();
  const resultsRef = useRef();

  return (
    <DefaultPageContainer>
      <article className="bg-light-green/50">
        <div className="spotlight-mask texture-pattern absolute z-10 h-[300vh] w-full bg-coral" />
        <div className="texture-pattern absolute z-0 h-[200vh] w-full bg-light-green" />
        <section className="relative z-10">
          <HeroHome {...hero} />
        </section>
        <ArchBackground>
          <section
            ref={openingRef}
            className="relative z-10 pb-[15vh] sm:pb-[33vh]"
          >
            <Opening next={challengeRef.current} {...opening} />
          </section>
        </ArchBackground>
        <div className="relative z-10 -mt-1 bg-viridian">
          <section
            ref={challengeRef}
            className="texture-pattern relative bg-viridian pt-6"
          >
            <Challenge next={approachRef.current} {...challenge} />
          </section>
          <section
            ref={approachRef}
            className="texture-pattern relative z-10 -mb-[110vh] bg-viridian pt-6"
          >
            <Approach next={resultsRef.current} {...approach} />
          </section>
          <div className="pointer-events-none">
            <ArchBackground color="black">
              <section ref={resultsRef} className="relative z-0 pt-6 pb-[33vh]">
                <Results {...results} />
              </section>
            </ArchBackground>
          </div>
        </div>
        <section className="texture-pattern relative z-10 -mt-px -mt-[33vh] bg-black">
          <ArchBackground color="coral">
            <StoriesOfChange {...stories} />
          </ArchBackground>
        </section>
        <Accordion items={preFooter} />
        <Footer />
      </article>
    </DefaultPageContainer>
  );
};

HomePage.defaultProps = {};

export default HomePage;
